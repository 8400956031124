<template>
  <b-overlay variant="white" :show="error">
    <template class="text-center" slot="overlay">
      Error in data provided
    </template>
    <b-container fluid class="px-0 text-center center">
      <b-row class="title mb-5 pb-3 justify-content-md-center">
        <b-col>
          <title-info condensed :info="tooltip ? tooltip : ''" justify="center">
            {{ title }}
          </title-info>
        </b-col>
      </b-row>

      <b-row class="mb-2 justify-content-md-center">
        <b-col class="donut">
          <div
            class="semi-donut"
            :style="{ '--percentage': score, '--fill': color || '#7aae30' }"
          >
            {{ $t("completed") }}
          </div>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center score">
        <b-col>
          <h1>{{ score }}%</h1>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import TitleInfo from "./TitleInfo.vue";

export default {
  components: { TitleInfo },
  data() {
    return {};
  },
  props: {
    metric: {
      type: String,
      required: true,
    },
    score: {
      type: [String, Number],
      required: true,
    },
    norm: {
      type: [String, Number],
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
  },
  mounted() {},
  created() {},
  methods: {},
  computed: {
    color() {
      if (this.score < 40) {
        return "#ff0000";
      } else if (this.score < 70) {
        return "#f3ad57";
      } else {
        return "#7aae30";
      }
    },
    error() {
      if (this.score === null || this.score === undefined) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.margin {
  margin: 25px;
}

.semi-donut {
  --percentage: 0;
  width: 250px;
  height: 125px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  overflow: hidden;
  color: #6f7787;
  /* color: var(--fill); */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  &:after {
    animation: 1s fillGraphAnimation ease-in;
    content: "";
    width: 250px;
    height: 250px;
    border: 35px solid;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) var(--fill)
      var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
  }
}
.score {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
}
.donut {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

@keyframes fillAnimation {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(135deg);
  }
}

@keyframes fillGraphAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
}
</style>
